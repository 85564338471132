import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assests/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assests/css/response.css";

// Admin Pannel

const AdminLogin = lazy(() => import("./component/AdminPannel/auth/SignIn/Index"));
const Assignment = lazy(() => import("./component/AdminPannel/Assignment/Assignment"));
const LiveExam = lazy(() => import("./component/AdminPannel/liveExam/LiveExam"));
const MyOrders = lazy(() => import("./component/AdminPannel/myorders/MyOrders"));
const Attendance = lazy(() => import("./component/AdminPannel/Pages/attendance/Attendance"));
const MyClasses = lazy(() => import("./component/AdminPannel/myclasses/MyClasses"));
const Doubt = lazy(() => import("./component/AdminPannel/doubt/Doubt"));
const AdminDashboard = lazy(() => import("./component/AdminPannel/Pages/Dashboard/Index"));
const AdminCourse = lazy(() => import("./component/AdminPannel/Pages/Courses/AddCourse"));
const AdminAllCourses = lazy(() => import("./component/AdminPannel/Pages/Courses/AllCourse"));
const AdminEditCourses = lazy(() => import("./component/AdminPannel/Pages/Courses/EditCourse"));
const AdminAddStudent = lazy(() => import("./component/AdminPannel/Pages/Student/AddStudent"));
const AdminAllStudent = lazy(() => import("./component/AdminPannel/Pages/Student/AllStudent"));
const AdminEditStudent = lazy(() => import("./component/AdminPannel/Pages/Student/EditStudent"));
const AdminAddInstructor = lazy(() => import("./component/AdminPannel/Pages/Instructor/AddInstructor"));
const AdminAllInstructor = lazy(() => import("./component/AdminPannel/Pages/Instructor/AllInstructor"));
const AdminEditInstructor = lazy(() => import("./component/AdminPannel/Pages/Instructor/EditInstructor"));
const AdminAddBatch = lazy(() => import("./component/AdminPannel/Pages/Batch/AddBatch"));
const AdminAllBatch = lazy(() => import("./component/AdminPannel/Pages/Batch/AllBatch"));
const AdminEditBatch = lazy(() => import("./component/AdminPannel/Pages/Batch/EditBatch"));
const AdminAddSyllabus = lazy(() => import("./component/AdminPannel/Pages/Syllabus/AddSyllabus"));
const AdminEditSyllabus = lazy(() => import("./component/AdminPannel/Pages/Syllabus/EditSyllabus"));
const AdminAllSyllabus = lazy(() => import("./component/AdminPannel/Pages/Syllabus/AllSyllabus"));
const AdminAddQuizzes = lazy(() => import("./component/AdminPannel/Pages/Quizzes/AddQuizzes"));
const AdminAllQuizzes = lazy(() => import("./component/AdminPannel/Pages/Quizzes/AllQuizzes"));
const AdminEditQuizzes = lazy(() => import("./component/AdminPannel/Pages/Quizzes/EditQuizzes"));
const AdminAllCtySyllabus = lazy(() => import("./component/AdminPannel/Pages/CategorySyllabus/Index"));
const AdminAllusers = lazy(() => import("./component/AdminPannel/Pages/UserList/Index"));
const AdminEditHome = lazy(() => import("./component/AdminPannel/Pages/Home/EditHome"));


const Home = lazy(() => import("./component/StudentPannel/home/Home"));
const Offer = lazy(() => import("./component/StudentPannel/what we offer/Offer"));
const Contact = lazy(() => import("./component/StudentPannel/contact/Contact"));
const AllEvent = lazy(() => import("./component/StudentPannel/AllEvent/AllEvent"));
const AllNews = lazy(() => import("./component/StudentPannel/AllNews/AllNews"));
const Internship = lazy(() => import("./component/StudentPannel/what we offer/Internship"));
const AllWebinar = lazy(() => import("./component/StudentPannel/AllWebinar/AllWebinar"));
const WebinarDetail = lazy(() => import("./component/StudentPannel/AllWebinar/WebinarDetail"));
const About = lazy(() => import("./component/StudentPannel/about/About"));
const CourseDetail = lazy(() => import("./component/StudentPannel/what we offer/CourseDetail"));
const EventDetail = lazy(() => import("./component/StudentPannel/AllEvent/EventDetail"));
const Hire = lazy(() => import("./component/StudentPannel/Hire With us/Hire"));
const Review = lazy(() => import("./component/StudentPannel/review/Review"));
const SignIn = lazy(() => import("./component/StudentPannel/auth/SignIn/Index"));
const SignUp = lazy(() => import("./component/StudentPannel/auth/SignUp/Index"));


const App = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
      <Routes>
          {/* // student pannel */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          {/* <Route path='/navbar' element={<Navbar/>}/> */}
          <Route path="/offer" element={<Offer />}></Route>
          <Route path="/internship" element={<Internship />} />
          <Route path="/course_detail" element={<CourseDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/all_event" element={<AllEvent />} />
          <Route path="/event_detail" element={<EventDetail />} />
          <Route path="/all_news" element={<AllNews />} />
          <Route path="/all_Webinar" element={<AllWebinar />} />
          <Route path="/webinar_detail" element={<WebinarDetail />} />
          <Route path="/hire" element={<Hire />} />
          <Route path="/review" element={<Review />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />

          {/* // admin Pannel */}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/assignment" element={<Assignment />} />
          <Route path="/admin/live_exam" element={<LiveExam />} />
          <Route path="/admin/my_orders" element={<MyOrders />} />
          <Route path="/admin/attendance" element={<Attendance />} />
          <Route path="/admin/my_classes" element={<MyClasses />} />
          <Route path="/admin/doubt" element={<Doubt />} />

          {/* new pannel/// */}
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/addcourse" element={<AdminCourse />} />
          <Route path="/admin/courses" element={<AdminAllCourses />} />
          <Route path="/admin/editcourse/:id" element={<AdminEditCourses />} />
          <Route path="/admin/addInstructor" element={<AdminAddInstructor />} />
          <Route path="/admin/instructors" element={<AdminAllInstructor />} />
          <Route path="/admin/editInstructor/:id" element={<AdminEditInstructor />} />
          <Route path="/admin/addstudent" element={<AdminAddStudent />} />
          <Route path="/admin/students" element={<AdminAllStudent />} />
          <Route path="/admin/editstudent/:id" element={<AdminEditStudent />} />
          <Route path="/admin/addbatch" element={<AdminAddBatch />} />
          <Route path="/admin/batchs" element={<AdminAllBatch />} />
          <Route path="/admin/editbatch/:id" element={<AdminEditBatch />} />
          <Route path="/admin/addsyllabus" element={<AdminAddSyllabus />} />
          <Route path="/admin/syllabus" element={<AdminAllSyllabus />} />
          <Route path="/admin/editsyllabus/:id" element={<AdminEditSyllabus />} />
          <Route path="/admin/addquizzes" element={<AdminAddQuizzes />} />
          <Route path="/admin/quizzes" element={<AdminAllQuizzes />} />
          <Route path="/admin/editquizzes/:id" element={<AdminEditQuizzes />} />
         <Route path="/admin/ctysyllabus" element={<AdminAllCtySyllabus />} />
         <Route path="/admin/userlist" element={<AdminAllusers />} />
         <Route path="/admin/edithome" element={<AdminEditHome/>} />
       </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
